<template>
    <div class="coach-detail">
        <head-img desc="教练团队" :imgUrl="horseBgUrl"></head-img>
        <my-menu ref="menu" :titleList="titleList"  :menuList="menuList"></my-menu>
        <div class="detail-content">
            <div class="content">
                <div class="text">
                    <el-image class="coach-img" :src="getImg(information.workPhotoUrl)" fit="cover"></el-image>
                    <div class="name">
                        <div class="title">
                            {{information.coachName}}
                            <el-tag size="mini" type="info">{{information.coachLevelName || '***'}}</el-tag>
                        </div>
                        <p v-html="information.professionalProfile"></p>
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            horseBgUrl: require('@/assets/img/home/lunbo4.jpg'),
            contentUrl: require('@/assets/img/news/course1.jpg'),
            titleList: [
                //{name: '骑马必须要注意的几个细节'},
            ],
            menuList: [
                {name: '首页', path: '/'},
                {name: '教练团队', path: '/coach'},
                {name: '一号教练'},
            ],
            information: {}
        }
    },
    methods: {
        getDetail() {
            this.$store.commit('loading', '.coach-detail, detail-content')
            this.$api.post('base/coach/coach-showForHomePage', this.$route.query).then(res=>{
                console.log(res)
                this.information = res.data
            })
            .finally(()=>{
                this.$store.commit('loaded')
            })
        }
    },
    mounted() {
        this.getDetail()
    }
}
</script>

<style lang="scss">
    @import "~@/assets/css/var";
    .coach-detail{
        .detail-content{
            display: flex;
            justify-content: center;
            .content{
                margin: 50px 0;
                width: 1200px;
                .title{
                    text-align: center;
                    font-size: 33px;
                    color: #262626;
                    margin-bottom: 30px;
                }
                .text{
                    display: flex;
                    .coach-img{
                        width: 300px;
                        height: 400px;
                    }
                    p{
                        width: 600px;
                        padding-left: 30px;
                        line-height: 30px;
                    }
                }
            }
        }
    }

</style>